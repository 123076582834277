@font-face {
  font-family: fa-brands;
  font-style: normal;
  font-weight: 400;
  src: local("fa-brands"), url(../fonts/fa-brands/fa-brands-400.woff2) format("woff2"), url(../fonts/fa-brands/fa-brands-400.woff) format("woff"), url(../fonts/fa-brands/fa-brands-400.ttf) format("ttf"), url(../fonts/fa-brands/fa-brands-400.eot) format("eot")
}

@font-face {
  font-family: fa;
  font-style: normal;
  font-weight: 300;
  src: local("fa"), url(../fonts/fa/fa-300.woff2) format("woff2"), url(../fonts/fa/fa-300.woff) format("woff"), url(../fonts/fa/fa-300.ttf) format("ttf"), url(../fonts/fa/fa-300.eot) format("eot")
}

@font-face {
  font-family: fa;
  font-style: normal;
  font-weight: 400;
  src: local("fa"), url(../fonts/fa/fa-400.woff2) format("woff2"), url(../fonts/fa/fa-400.woff) format("woff"), url(../fonts/fa/fa-400.ttf) format("ttf"), url(../fonts/fa/fa-400.eot) format("eot")
}

@font-face {
  font-family: fa;
  font-style: normal;
  font-weight: 900;
  src: local("fa"), url(../fonts/fa/fa-900.woff2) format("woff2"), url(../fonts/fa/fa-900.woff) format("woff"), url(../fonts/fa/fa-900.ttf) format("ttf"), url(../fonts/fa/fa-900.eot) format("eot")
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

$width: 1140px;

$lg: 'all and (min-width: 768px)';
$tablet: 'all and (max-width: 1023px)';
$mobile: 'all and (max-width: 767px)';
$sm: 'all and (max-width: 359px)';

$light-gray: #c9c9c9;
$green: #4ac0ca;
$blue: #38B5D6;

$font-1: 'Open Sans', sans-serif;
$font-2: 'Montserrat', sans-serif;

body {
  font-family: $font-1;
  color: #8e8e8e;
  font-size: 14px;
  line-height: 1.4;
  @media #{$mobile} {
    padding-top: 50px;
  }
  &.menu-fixed {
    padding-top: 118px;
    @media #{$mobile} {
      padding-top: 50px;
    }
  }
}

.container {
  width: 100%;
  max-width: $width;
  margin: 0 auto;
  padding: 0 15px;
}


a {
  color: inherit;
  text-decoration: none;
  transition-duration: .3s;
}


img {
  display: block;
  height: auto;
  max-width: 100%;
}

h1,
h2,
h3,
h4 {
  margin: 0.8em 0;
}

h1,
h2 {
  color: $green;
  font-weight: 500;
  font-family: $font-2;
}

h1 {
  margin: 40px 0;
  color: $blue;
  font-size: 25px;
}

h2,
.title--boder {
  position: relative;
  display: inline-block;
  padding-bottom: 10px;
  font-size: 20px;
  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    background: $green;
  }
}

.title--boder {
  margin: 0.8em 0;
  color: $green;
}

p {
  margin: 1em 0;
  &:last-child {
    margin-bottom: 0;
  }
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid $green;
  background: $green;
  color: #fff;
  cursor: pointer;
  transition-duration: .3s;
  &:hover {
    background: #fff;
    color: $green;
  }
}