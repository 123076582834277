.footer {
  margin-top: 45px;
  padding: 20px 0;
  border-top: 1px solid rgba(0,0,0,0.075);
  color: $light-gray;
  font-size: 12px;
  text-align: center;
}

.cookieConsent {
  display: none;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 100;
  width: 100%;
  padding: 15px 30px;
  background: #fff;
  box-shadow: 1px 9px 15px 2px rgba(0,0,0,0.7);
}

.cookieConsent__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cookieConsentOK {
  display: inline-block;
  margin-left: 20px;
}
