.cards {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.card {
  width: 33.3333%;
  margin-bottom: 30px;
  padding: 0 15px;
  &--art {
    width: 50%;
  }
  @media #{$mobile} {
    width: 100%;
  }
  &__title {
    margin: 0 0 20px;
    font-size: 18px;
    &:after {
      width: 50%;
      transition: all 0.4s ease;
    }
    &--link:hover:after {
      width: 100%;
    }
  }
  &__text {
    text-align: justify;
  }
  &--lg {
    display: flex;
    align-items: flex-end;
    width: 100%;
    padding: 0;
    @media #{$mobile} {
      display: block;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }
  &__left {
    width: 33.3333%;
    padding: 0 15px;
    @media #{$mobile} {
      width: 100%;
    }
  }
  &__right {
    width: 66.6667%;
    padding: 0 15px;
    @media #{$mobile} {
      width: 100%;
    }
  }
  &--lg--odd {
    .card__left {
      width: 66.6667%;
      @media #{$mobile} {
        width: 100%;
      }
    }
    .card__right {
      width: 33.3333%;
      @media #{$mobile} {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
  &__intro {
    position: relative;
    margin-bottom: 40px;
    &:after {
      content: '';
      position: absolute;
      bottom: -20px;
      left: 0;
      width: 30%;
      height: 1px;
      background: #8e8e8e;
      opacity: .5;
    }
  }
  &--blog {
    width: 100%;
  }
  &__btn {
    margin-top: 15px;
  }
}

.date-info {
  margin-top: 10px;
  color: #c9c9c9;
  font-size: 13px;
  font-style: italic;
}