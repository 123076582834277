.header {
  position: relative;
  padding-top: 56px;
  @media #{$mobile} {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: 50px;
    padding: 0 15px;
    background: #fff;
    box-shadow: -4px -4px 15px -5px rgba(0,0,0,0.7);
  }
  &__logo {
    padding: 4px 0 23px;
    display: flex;
    justify-content: center;
    @media #{$mobile} {
      width: 130px;
      margin: 0;
      padding: 0;
    }
  }
  &__toggle {
    display: none;
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    &:before {
      content: "\f0c9";
      color: $green;
      font: 27px 'fa';
    }
    @media #{$mobile} {
      display: block;
    }
  }
  &__collapse {
    @media #{$mobile} {
      display: none;
      position: fixed;
      top: -100%;
      left: 0;
      flex-direction: column;
      width: 100%;
      height: 0;
      overflow-y: auto;
      background: #fff;
      opacity: 0;
      transition: opacity 0.5s;
    }
  }
  &__top {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    color: $light-gray;
    font-size: 12px;
    @media #{$mobile} {
      order: 2;
      position: static;
      transform: translateX(0);
    }
  }
  &__social {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    font-size: 20px;
    &__item {
      margin-left: 10px;
    }
    &__link {
      &:hover {
        color: $green;
      }
      &:before {
        font-family: 'fa-brands';
      }
      &--fb:before {
        content: "\f082";
      }
      &--tw:before {
        content: "\f16d";
      }
    }
  }
  &__menu {
    padding: 0 160px;
    border-top: 1px solid rgba(0, 0, 0, 0.075);
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    background: #fff;
    line-height: 1.333;
    @media #{$tablet} {
      padding-right: 15px;
      padding-left: 130px;
    }
    @media #{$mobile} {
      order: 1;
      margin-bottom: 10px;
      padding: 0;
      border: 0;
    }
  }
}

.menu-fixed {
  .header__logo {
    @media #{$lg} {
      position: fixed;
      left: 50%;
      top: 11px;
      z-index: 101;
      width: 130px;
      margin-left: -553px;
    }
    @media (max-width: $width) {
      left: 15px;
      margin: 0;
    }
    @media (min-width: 768px) and (max-width: 1023px) {
      width: 110px;
    }
  }
  .header__menu {
    @media #{$lg} {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      box-shadow: -4px -4px 15px -5px rgba(0,0,0,0.7);
      border-color: transparent;
    }
  }
  .menu--main {
    @media (min-width: 768px) and (max-width: 1023px) {
      justify-content: flex-start;
    }
  }
}

.menu--main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  color: $light-gray;
  @media #{$mobile} {
    display: block;
  }
  li {
    position: relative;
    @media #{$mobile} {
      padding: 10px 0;
    }
  }
  > li {
    > a {
      display: block;
      position: relative;
      padding: 17px 20px;
      @media #{$tablet} {
        padding: 17px 13px;
      }
      @media #{$mobile} {
        padding: 0;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background: rgba(0, 0, 0, 0.03);
        transition: all 0.4s ease;
        @media #{$mobile} {
          content: none;
        }
      }
      &:hover:after {
        height: 100%;
      }
      &.isActive {
        color: $green;
      }
    }
  }
}

.dropdown--toggle:hover {
  + .dropdown {
    @media #{$lg} {
      display: block;
    }
  }
}

.dropdown {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  min-width: 160px;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  @media #{$mobile} {
    position: static;
    padding-left: 20px;
    border: 0;
    box-shadow: none;
  }
  &:hover {
    display: block;
  }
  a {
    display: block;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    padding: 12px 10px;
    @media #{$mobile} {
      padding: 0;
      border: 0;
      font-size: 12px;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.03);
      @media #{$mobile} {
        background: 0;
      }
    }
  }
  li:last-child > a {
    border: 0;
  }
}

.dropdown--level-2 {
  top: 0;
  left: 100%;
}

.menu-open {
  .header__toggle:before {
    content: "\f00d";
  }
  .header__collapse {
    top: 50px;
    display: flex;
    opacity: 1;
    height: calc(100% - 50px);
  }
}

.dropdown--toggle {
  @media #{$mobile} {
    position: relative;
    padding-right: 35px;
    &__icon {
      &:before {
        content: "\f105";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        font: 25px/1 'fa';
      }

      &.isActive:before {
        content: '\f107';
      }
    }
  }
}