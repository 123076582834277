.block--article {
  padding-top: 30px;
  &__subtitle {
    color: #8e8e8e;
    font-size: 16px;
  }
  &__info {
    display: flex;
    flex-wrap: wrap;
    &--left {
      width: 75%;
      padding-right: 15px;
      @media #{$mobile} {
        order: 2;
        width: 100%;
        padding: 0;
      }
    }
    &--right {
      width: 25%;
      padding-left: 15px;
      @media #{$mobile} {
        order: 1;
        width: 100%;
        padding: 10px 0 30px;
      }
    }
    &__title {
      margin-top: 0;
      font-weight: bold;
      font-size: 16px;
    }
  }
  &__detail {
    img {
      margin: 1em 0;
    }
  }
}