.block__hp {
  display: flex;
  margin: 30px auto 45px;
  @media #{$mobile} {
    display: block; 
  }
  &--left {
    flex: 0 0 165px;
    position: relative;
    padding-top: 240px;
    margin-right: 30px;
    margin-bottom: 30px;
    text-align: center;
    @media (max-width: 1023px) {
      padding: 0;
    }
    &:hover {
      .block__hp__img--2 {
        opacity: 1;
      }
    }
  }
  &--right {
    font-size: 16px;
    @media #{$mobile} {
      font-size: 15px;
    }
  }
  &__img {
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.4s ease;
    @media (max-width: 1023px) {
      position: static;
      margin: 0 auto 15px;
    }
    @media #{$mobile} {
      width: 150px;
    }
    &--1 {
      @media (max-width: 1023px) {
        display: none;
      }
    }
    &--2 {
      opacity: 0;
      z-index: 1;
      @media (max-width: 1023px) {
        opacity: 1;
      }
    }
  }
  &__name {
    font-size: 16px;
  }
  &__intro {
    color: $green;
    @media #{$mobile} {
      font-size: 16px;
    }
  }
}

.blockquote {
  margin: 30px 0 0;
  padding: 0;
  text-align: center;
  &__footer {
    padding-right: 15%;
    text-align: right;
    font-size: 16px;
  }
}