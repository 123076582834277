.block__slider {
  @media #{$mobile} {
    margin-top: 25px;
  }
}

.slider:hover {
  .owl-nav,
  .slide__content {
    opacity: 1;
  }
}

.owl-nav {
  opacity: 0;
  transition: all 0.4s ease;
  @media #{$mobile} {
    opacity: 1;
  }
  button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    padding: 0;
    outline: 0;
    border: 0;
    background: 0;
    transition-duration: .3s;
    font-size: 0;
    opacity: .5;
    &:hover {
      color: $green;
      opacity: 1;
    }
    &:before {
      font: 900 45px/1 'fa';
      @media #{$mobile} {
        font-weight: 400;
        font-size: 25px;
      }
    }
  }
}

.owl-prev {
  left: 15px;
  &:before {
    content: "\f104";
  }
}

.owl-next {
  right: 15px;
  &:before {
    content: "\f105";
  }
}

.owl-dots {
  display: flex;
  justify-content: center;
  padding: 25px 0;
  @media #{$mobile} {
    padding: 15px 0;
  }
}

.owl-dot {
  display: block;
  width: 15px;
  height: 15px;
  margin: 0 5px;
  padding: 0;
  outline: 0;
  border: 0;
  border-radius: 50%;
  background: $green;
  @media #{$mobile} {
    width: 8px;
    height: 8px;
    margin: 0 3px;
  }
  &.active {
    background: $light-gray;
  }
}

.no--dots .owl-dots {
  display: none;
}

.slide {
  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 15px;
    background: rgba(0, 0, 0, 0.3);
    transition: all 0.4s ease;
    color: #fff;
    text-align: center;
    font-size: 13px;
    opacity: 0;
    @media #{$mobile} {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;
      opacity: 1;
      font-size: 12px;
    }
  }
  &__title {
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 25px;
    text-transform: uppercase;
    @media #{$mobile} {
      font-size: 16px;
    }
    &:after {
      content: none;
    }
  }
  &__text {
    @media #{$mobile} {
      display: none;
    }
  }
  &__view {
    display: inline-block;
    margin-top: 5px;
    padding: 10px 15px;
    border-top: 3px solid #fff;
    text-transform: uppercase;
  }
  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-indent: -9999px;
    overflow: hidden;
  }
}