.block--contact {
  display: flex;
  margin-top: 40px;
  @media #{$mobile} {
    flex-direction: column;
    margin: 0;
  }
  &__left {
    width: 60%;
    @media #{$mobile} {
      order: 2;
      width: 100%;
    }
  }
  &__right {
    width: 40%;
    padding-left: 40px;
    @media #{$mobile} {
      order: 1;
      width: 100%;
      padding: 0 0 30px;
    }
  }
  &__map {
    width: 100%;
  }
}